import React from "react";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Link from "@mui/material/Link";

function Publications() {
    return (
        <Grid container spacing={2} mb={5} justifyContent="center">
            <Grid item xs={12} sm={9} wrap>
                <Typography variant="h4" gutterBottom>
                    Publications and Presentations
                </Typography>

                <Typography variant="body1" mb={3}>
                    In addition to engaging with the community, the research
                    team has shared results of the EdConnect Evaluation Study
                    outside of HCS through various publications and
                    presentations, including:
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Education and Information Technologies</strong> (2024). Parents' use of technology to stay informed about their children's education.
                </Typography>
                <ul>
                    <li>
                        {" "}
                        <Link
                            href="https://rdcu.be/dIQWJ"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access paper here
                        </Link>
                    </li>
                </ul>

                <Typography variant="body1" gutterBottom>
                    <strong>Boston College Graduate Research Symposium</strong> (2024).
                    Examining the Relationship Between Broadband Access, Parent Technology Use, and Student Academic Outcomes.
                </Typography>
                <ul>
                    <li>
                        {" "}
                        <Link
                            href="https://www.researchgate.net/publication/379179216_Examining_the_Relationship_Between_Broadband_Access_Parent_Technology_Use_and_Student_Academic_Outcomes_Contact_Information"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access poster here
                        </Link>
                    </li>
                </ul>


                <Typography variant="body1" gutterBottom>
                    <strong>Computers in the Schools</strong> (2023).
                    Exploring Parents’ Access, Beliefs, and Use of Educational
                    Technology across a Community-Wide Broadband Initiative.
                </Typography>
                <ul>
                    <li>
                        {" "}
                        <Link
                            href="https://www.tandfonline.com/doi/full/10.1080/07380569.2023.2271490"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access paper here
                        </Link>
                    </li>
                </ul>

                <Typography variant="body1" gutterBottom>
                    <strong>Journal of Research on Technology in Education</strong> (2023).
                    Parent use of and beliefs about technology for education and parenting: Year 1 results from the HCS EdConnect study.
                </Typography>
                <ul>
                    <li>
                        {" "}
                        <Link
                            href="https://www.tandfonline.com/doi/full/10.1080/15391523.2023.2276410"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access paper here
                        </Link>
                    </li>
                </ul>



                <Typography variant="body1" gutterBottom>
                    <strong>
                        54th Annual Meeting of the New England Educational
                        Research Organization (NEERO)
                    </strong>{" "}
                    (May 2023).
                </Typography>
                <ol>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            Do Parents Act As They Believe? Exploring
                            Discrepancies Between Parents’ Beliefs and Practices
                            Toward Technology.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" gutterBottom>
                            Exploring Parents’ Evolving Educational Technology
                            Usage and Beliefs In A Post-Pandemic Era.
                        </Typography>
                    </li>
                </ol>

                <Typography variant="body1" gutterBottom>
                    <strong>
                        American Educational Research Association (AERA) 2023
                        Annual Meeting
                    </strong>{" "}
                    (April 2023). Exploring parents’ access, beliefs, and use of
                    educational technology across a community-wide broadband
                    initiative.
                </Typography>
                <ul>
                    <li>
                        {" "}
                        <Link
                            href="https://www.aera.net/Publications/Online-Paper-Repository/AERA-Online-Paper-Repository-Viewer/ID/2012180"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access paper here
                        </Link>
                    </li>
                    <li>
                        {" "}
                        <Link
                            href="https://aera23-aera.ipostersessions.com/Default.aspx?s=8A-85-BE-CD-22-AB-9D-0B-02-1E-04-C7-ED-8B-C3-C0"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access virtual poster here
                        </Link>
                    </li>
                </ul>

                <Typography variant="body1" gutterBottom>
                    <strong>
                        U.S. Department of Commerce Digital Equity Technical
                        Assistance Tiger Team
                    </strong>{" "}
                    (December 2022). Evaluating Community Broadband: Lessons and
                    Examples from the EdConnect Evaluation.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>
                        Detroit Digital Inclusion Research Symposium
                    </strong>{" "}
                    (October 2022). Connecting Parents, Schools, and Community
                    Broadband: Lessons from the EdConnect Evaluation Study.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Connected Learning Summit</strong> (July 2022).
                    Across the Digital Divide: Parents’ Use of Technology for
                    Supporting Their Children in School.
                </Typography>
                <ul>
                    <li>
                        <Link
                            href="https://press.etc.cmu.edu/proceedings/proceedings-2022-connected-learning-summit"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access paper here
                        </Link>
                    </li>
                </ul>

                <Typography variant="body1" gutterBottom>
                    <strong>
                        53rd Annual Meeting of the New England Educational
                        Research Organization (NEERO)
                    </strong>{" "}
                    (April 2022). Exploring parents’ use and beliefs towards
                    technology and broadband in supporting K12 education.
                </Typography>

                <ul>
                    <li>
                        <Link
                            href="https://www.researchgate.net/publication/360900979_Exploring_Parents'_Use_and_Beliefs_Towards_Technology_and_Broadband_in_Supporting_K12_Education"
                            variant="body2"
                            target="_blank"
                            gutterBottom
                        >
                            Access poster here
                        </Link>
                    </li>
                </ul>

                <Typography variant="body1" gutterBottom>
                    <strong>
                        Arizona State University ShapingEDU Action Team
                    </strong>{" "}
                    (February 2022). An Introduction to the HCS EdConnect
                    Evaluation.
                </Typography>

                {/* <Typography variant="body1" gutterBottom>
                    <strong>
                        Journal of Research and Technology in Education
                    </strong>{" "}
                    (Pending journal review). Parent use of and beliefs about
                    technology for education and parenting: Year 1 results from
                    the ACS EdConnect study.
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <strong>Computers in the Schools</strong> (Pending journal
                    review). Exploring parents’ access, beliefs, and use of
                    educational technology across a community-wide broadband
                    initiative.
                </Typography> */}
            </Grid>
        </Grid>
    );
}

export default Publications;
